import React, { useState, useEffect } from "react";
import axios from "axios";
import "./buttonVote.css";

const SparkleButtonWithCounter = () => {
  const [voteCount, setVoteCount] = useState(0);
  const [hasVoted, setHasVoted] = useState(null); // null represents no token

  useEffect(() => {
    // Fetch the vote count on mount
    const fetchVoteCount = async () => {
      try {
        const response = await axios.get("https://unity.beratt.eu/api/getvote");
        setVoteCount(response.data.vote_count);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du nombre de votes :",
          error
        );
      }
    };

    const checkVote = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.post(
            "https://unity.beratt.eu/api/checkvote",
            { token }
          );
          setHasVoted(response.data.voted);
        } catch (error) {
          console.error("Erreur lors de la vérification du vote :", error);
        }
      } else {
        setHasVoted(null); // No token found
      }
    };

    fetchVoteCount();
    checkVote();
  }, []); // Empty dependency array to run only once on mount

  const handleVote = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    try {
      const response = await axios.post(
        "https://unity.beratt.eu/api/postvote",
        { token }
      );

      console.log(response);
      setHasVoted(true);
      setVoteCount((prevCount) => prevCount + 1);
    } catch (error) {
      console.error("Erreur lors de l'envoi du vote :", error);
    }
  };

  return (
    <div className="sparkle-button-with-counter flex flex-col justify-center items-center">
      <button className="sparkle-button" onClick={handleVote}>
        {[...Array(5)].map((_, index) => (
          <svg
            key={index}
            viewBox="0 0 96 96"
            xmlns="http://www.w3.org/2000/svg"
            className={`sparkle sparkle-${index + 1}`}>
            <path d="M93.781 51.578C95 50.969 96 49.359 96 48c0-1.375-1-2.969-2.219-3.578 0 0-22.868-1.514-31.781-10.422-8.915-8.91-10.438-31.781-10.438-31.781C50.969 1 49.375 0 48 0s-2.969 1-3.594 2.219c0 0-1.5 22.87-10.406 31.781-8.908 8.913-31.781 10.422-31.781 10.422C1 45.031 0 46.625 0 48c0 1.359 1 2.969 2.219 3.578 0 0 22.873 1.51 31.781 10.422 8.906 8.911 10.406 31.781 10.406 31.781C45.031 95 46.625 96 48 96s2.969-1 3.562-2.219c0 0 1.523-22.871 10.438-31.781 8.913-8.908 31.781-10.422 31.781-10.422Z" />
          </svg>
        ))}
        <span>
          {hasVoted === null
            ? "Connectez vous !"
            : hasVoted
            ? "Merci !"
            : "Voter !"}
        </span>
        <span aria-hidden="true">
          {hasVoted === null
            ? "Connectez vous !"
            : hasVoted
            ? "Merci !"
            : "Voter !"}
        </span>
      </button>
      <span className="text-white text-2xl text-center">
        Votez et soutenez Epicard pour rejoindre notre communauté !
      </span>
      <span className="sparkle-button">
        <span>{voteCount}</span>
        <span aria-hidden="true">{voteCount}</span>
      </span>
    </div>
  );
};

export default SparkleButtonWithCounter;
