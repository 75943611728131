import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Utilisation de useNavigate
import backgroundVideo from "../assets/etoiles_bleu.mp4";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate(); // Pour naviguer vers d'autres pages

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      alert("Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      const response = await axios.post(
        "https://unity.beratt.eu/api/register",
        {
          username,
          password,
        }
      );

      if (response.status === 201) {
        localStorage.setItem("token", response.data.token);
        navigate("/"); // Redirection vers la page de connexion après enregistrement
      } else {
        console.error("Erreur lors de l'enregistrement.");
      }
    } catch (error) {
      console.error("Erreur lors de l'enregistrement:", error);
      alert("Enregistrement échoué. Veuillez réessayer.");
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      {/* Vidéo en arrière-plan */}
      <video
        src={backgroundVideo}
        autoPlay
        loop
        muted
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: "-1",
        }}
      />

      <div
        className="absolute cursor-pointer hover:scale-110 transition-transform duration-200"
        style={{ top: "1%", right: "2%" }}
        onClick={() => navigate("/login")}>
        <span className="text-white font-bold" style={{ fontSize: "3vw" }}>
          X
        </span>
      </div>

      <div
        style={{ width: "30%", height: "60%", borderRadius: "5%" }}
        className="bg-blue-900 bg-opacity-40">
        <div className="text-center" style={{ marginTop: "10%" }}>
          <span className="text-white font-bold" style={{ fontSize: "3vw" }}>
            Sign Up
          </span>
        </div>
        <div
          className="flex flex-col justify-center"
          style={{ width: "70%", marginTop: "6%", marginLeft: "15%" }}>
          <span className="text-white font-bold" style={{ fontSize: "1.5vw" }}>
            Username
          </span>
          <input
            style={{ padding: "2%", borderRadius: "5px" }}
            onChange={(e) => setUsername(e.target.value)}
          />
          <span className="text-white font-bold" style={{ fontSize: "1.5vw" }}>
            Password
          </span>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            style={{ padding: "2%", borderRadius: "5px" }}
          />
          <span className="text-white font-bold" style={{ fontSize: "1.5vw" }}>
            Comfirm password
          </span>
          <input
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={{ padding: "2%", borderRadius: "5px" }}
          />
        </div>
        <div
          className="flex justify-center hover:scale-110 transition-transform duration-200"
          style={{ width: "100%", marginTop: "10%" }}>
          <button
            className="bg-white text-black font-bold"
            onClick={handleRegister}
            style={{ padding: "3%", borderRadius: "10px" }}>
            REGISTER
          </button>
        </div>
        <div
          className="flex justify-center hover:scale-110 transition-transform duration-200 cursor-pointer"
          style={{ marginTop: "2%" }}
          onClick={() => navigate("/login")}>
          <span className="text-white font-bold" style={{ fontSize: "1vw" }}>
            Login ?
          </span>
        </div>
      </div>
    </div>
  );
};

export default Register;
