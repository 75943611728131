import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Utilisation de useNavigate
import backgroundVideo from "../assets/etoiles_bleu.mp4";
import axios from "axios";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post("https://unity.beratt.eu/api/login", {
        username,
        password,
      });
      // Suppose que le token est retourné dans response.data.token
      const token = response.data.token;
      if (token) {
        localStorage.setItem("token", token); // Stockage du token dans le localStorage
        console.log("Token enregistré :", token);
        navigate("/"); // Exemple de redirection après connexion
      } else {
        console.error("Token non trouvé dans la réponse.");
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
      alert("Connexion échouée. Veuillez vérifier vos informations.");
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      {/* Vidéo en arrière-plan */}
      <video
        src={backgroundVideo}
        autoPlay
        loop
        muted
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: "-1",
        }}
      />

      <div
        className="absolute cursor-pointer hover:scale-110 transition-transform duration-200"
        style={{ top: "1%", right: "2%" }}
        onClick={() => navigate("/")}>
        <span className="text-white font-bold" style={{ fontSize: "3vw" }}>
          X
        </span>
      </div>

      <div
        style={{ width: "30%", height: "60%", borderRadius: "5%" }}
        className="bg-blue-900 bg-opacity-40">
        <div className="text-center" style={{ marginTop: "10%" }}>
          <span className="text-white font-bold" style={{ fontSize: "3vw" }}>
            Connexion
          </span>
        </div>
        <div
          className="flex flex-col justify-center"
          style={{ width: "70%", marginTop: "10%", marginLeft: "15%" }}>
          <span className="text-white font-bold" style={{ fontSize: "1.5vw" }}>
            Username
          </span>
          <input
            style={{ padding: "2%", borderRadius: "5px" }}
            onChange={(e) => setUsername(e.target.value)}
          />
          <span className="text-white font-bold" style={{ fontSize: "1.5vw" }}>
            Password
          </span>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            style={{ padding: "2%", borderRadius: "5px" }}
          />
        </div>
        <div
          className="flex justify-center hover:scale-110 transition-transform duration-200"
          style={{ width: "100%", marginTop: "10%" }}>
          <button
            className="bg-white text-black font-bold"
            onClick={handleLogin}
            style={{ padding: "3%", borderRadius: "10px" }}>
            CONNEXION
          </button>
        </div>
        <div
          className="flex justify-center hover:scale-110 transition-transform duration-200 cursor-pointer"
          style={{ marginTop: "2%" }}
          onClick={() => navigate("/register")}>
          <span className="text-white font-bold" style={{ fontSize: "1vw" }}>
            Register ?
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
